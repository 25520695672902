<template>
	<nav id="navbar" :class="{ classroom_style: true }">
		<div
			class="navbar_top"
			:class="{
				ChargeMeTribe_Page:
					($route.path.includes('ChargeMeTribe') ||
						chargeMePage.includes('ChargeMeTribe')) &&
					$route.path !== '/',
				'navbar-linear': $route.path == '/',
				'bg-white border-bottom': $route.path == '/ChargeMeContactBook/Note1',
				'bg-white border-bottom': $route.path.includes(
					'/ChargeMeEnterpriseGPT'
				),
			}"
		>
			<div class="ClassRoom">
				<template v-if="$route.path.includes('/ChargeMeEnterpriseGPT')">
					<span class="logo">
						<img
							class="logoImg"
							src="https://chargemestorage.blob.core.windows.net/chargeme-image/喬米LOGO (10).png"
							alt=""
						/>
					</span>
					<h3 class="systemTitle">露營資訊管理系統</h3>
				</template>
				<template v-else>
					<router-link
						:to="this.$store.state.auth.status.loggedIn ? chargeMePage : '/'"
						@click="$store.state.selectTagId = 'All'"
					>
						<span class="logo">
							<img
								class="logoImg"
								src="https://chargemestorage.blob.core.windows.net/chargeme-image/喬米LOGO (10).png"
								alt=""
							/>
						</span>
					</router-link>
				</template>
			</div>
			<div class="middle_part">
				<div
					:class="{ bottomMenu: $store.getters.mobileSize }"
					v-if="
						!$store.getters.mobileSize &&
						$route.path != '/' &&
						chargeMePage.includes('ChargeMeContactBook') &&
						$route.path != '/ChargeMeContactBook/Note1' &&
						!$route.path.includes('/ChargeMeEnterpriseGPT')
					"
				>
					<el-button
						class="ms-3 menu"
						v-if="this.$store.state.auth.status.loggedIn"
						:class="{ activePage: classMenu == true }"
					>
						<router-link to="/ChargeMeContactBook/ClassRoomCenter">
							<i class="fal fa-book" style="color: #000000"></i>
							<span>課程選單</span>
						</router-link>
					</el-button>
					<el-button
						class="ms-3 menu"
						v-if="this.$store.state.auth.status.loggedIn"
						:class="{
							activePage: $route.name == 'ChargeMeContactBook_Curriculum',
						}"
					>
						<router-link to="/ChargeMeContactBook/Curriculum">
							<i class="fal fa-calendar-week"></i>
							<span>課&emsp;&emsp;表</span>
						</router-link>
					</el-button>
					<el-button
						class="ms-3 menu"
						v-if="this.$store.state.auth.status.loggedIn"
						:class="{
							activePage: $route.name == 'ChargeMeContactBook_HomeWork',
						}"
					>
						<router-link to="/ChargeMeContactBook/HomeWork">
							<i class="fal fa-comment-alt-edit"></i>
							<span>作業清單</span>
						</router-link>
					</el-button>
					<el-button
						@click="this.$store.commit('OpenMessageWin')"
						class="ms-3message menu align-items-center"
						v-if="this.$store.state.auth.status.loggedIn"
					>
						<i class="far fa-comments-alt"></i>
						<span>即時通訊</span>
					</el-button>
				</div>
				<div
					:class="{ bottomMenu: $store.getters.mobileSize }"
					v-if="
						!$store.getters.mobileSize &&
						$route.path != '/' &&
						(chargeMePage.includes('ChargeMeTribe') ||
							chargeMePage.includes('ChargeMeTribeIndex'))
					"
				>
					<el-button
						class="ms-3 menu"
						v-if="this.$store.state.auth.status.loggedIn"
						:class="{
							activePage:
								$route.name == 'ChargeMeTribe' ||
								$route.name == 'ChargeMeTribeIndex',
						}"
					>
						<router-link :to="`/ChargeMeTribe`">
							<i class="fal fa-home" style="color: #000000"></i>
							<span>貼&emsp;&emsp;文</span>
						</router-link>
					</el-button>
					<el-button
						class="ms-3 menu"
						v-if="this.$store.state.auth.status.loggedIn"
						:class="{ activePage: $route.name == 'ChargeMeTribe_ClassPage' }"
					>
						<router-link to="/ChargeMeTribe/ClassPage">
							<i class="fal fa-book"></i>
							<span>課&emsp;&emsp;程</span>
						</router-link>
					</el-button>
					<el-button
						class="ms-3 menu"
						v-if="this.$store.state.auth.status.loggedIn"
						:class="{ activePage: $route.name == 'ChargeMeTribe_Connection' }"
					>
						<router-link to="/ChargeMeTribe/Connection">
							<i class="fal fa-people-dress-simple"></i>
							<span>人&emsp;&emsp;脈</span>
						</router-link>
					</el-button>
					<el-button
						@click="this.$store.commit('OpenMessageWin')"
						class="ms-3message menu align-items-center"
						v-if="this.$store.state.auth.status.loggedIn"
					>
						<i class="far fa-comments-alt"></i>
						<span>通&emsp;&emsp;訊</span>
					</el-button>
				</div>
			</div>
			<el-button
				v-if="$route.name == 'Home' || $route.path == '/'"
				class="ms-auto me-3 loginBtn"
			>
				<router-link
					v-if="$store.state.auth.status.loggedIn == true"
					to="/ChargeMeContactBook/ClassRoomCenter"
					>進入
				</router-link>
				<router-link v-else to="/login">登入</router-link></el-button
			>
			<template v-if="isLauncherOpen">
				<div class="launcherBack" @click="isLauncherOpen = !isLauncherOpen">
					<div class="launcher">
						<div class="alwaysShow p-0">
							<ul class="list-group text-dark">
								<li
									class="list-group-item text-dark"
									@click="turnPage('ChargeMeContactBook')"
								>
									<div class="p-2" @click="drawer = false">
										<i class="far fa-book me-2"></i>
										<span>喬米聯絡簿</span>
									</div>
								</li>
								<!-- <li class="list-group-item" @click="turnPage('ChargeMeTribe')">
									<div class="d-flex justify-content-between w-100">
										<div class="p-2" @click="drawer = false">
											<i class="fa-sharp fa-regular fa-earth-americas me-2"></i>
											<span>喬米部落</span>
										</div>
									</div>
								</li> -->
								<!-- <li class="list-group-item">
									<div class="d-flex justify-content-between w-100">
										<div class="p-2 text-secondary" @click="drawer = false">
											<i class="fa-sharp fa-light fa-atom me-2"></i>
											<span>喬米GPT</span>
										</div>
									</div>
								</li> -->
							</ul>
						</div>
					</div>
				</div>
			</template>

			<div
				class="menu_user"
				v-if="!$route.path.includes('/ChargeMeEnterpriseGPT')"
			>
				<el-button class="p-0" @click="toggleLauncher" link>
					<i class="fa-sharp fa-solid fa-grip-dots"></i>
				</el-button>

				<el-button class="menu p-0" @click="drawer = true">
					<div>
						<i
							class="fa-sharp fa-solid fa-bars ham"
							v-if="currentUser() === null || currentUser().avatar == ''"
						></i>
						<div class="img_avatar" v-else>
							<el-avatar :src="currentUser().avatar" :size="30"></el-avatar>
						</div>
					</div>
				</el-button>
			</div>
			<template v-else>
				<el-tooltip effect="dark" content="登出" placement="bottom">
					<div @click="logout" class="text-dark">
						<div class="p-2 ps-3" @click="drawer = false">
							<i class="far fa-sign-out-alt"></i>
						</div>
					</div>
				</el-tooltip>
			</template>
		</div>
		<div
			v-if="
				(($route.name == 'ChargeMeContactBook_ContactBook' &&
					$store.state.classRoomTabActiveName == 'second') ||
					($route.name == 'ChargeMeContactBook_ClassRoomCenter' &&
						!$store.state.classRoomCenterActiveMode)) &&
				startSearch
			"
			class="mb-2"
		>
			<el-input
				v-model="searchStr"
				placeholder="請輸入搜尋內容"
				@input="searchData()"
			></el-input>
		</div>
		<div class="side_bar">
			<el-drawer
				v-model="drawer"
				:size="!$store.getters.mobileSize ? '20vw' : '60vw'"
				:show-close="false"
			>
				<template #title>
					<div v-if="!isLoginAndHasName">
						<div class="main mb-1 text-start">快速導覽</div>
						<span class="additional" v-if="$store.state.auth.user == null"
							>請先登入！</span
						>
					</div>
					<div v-else>
						<div class="top">
							<div class="img_avatar">
								<img class="avatar" :src="currentUser().avatar" alt="avatar" />
							</div>
							<div
								class="main"
								:class="{ computer: !$store.getters.mobileSize }"
							>
								<div v-if="$store.state.auth.user != null" class="name">
									{{ this.$store.state.auth.user.name }}
								</div>
								<p
									v-if="
										currentUser() != null &&
										!currentUser().role.includes('Teacher')
									"
								>
									&nbsp;會員
								</p>
								<p v-else>老師</p>
							</div>
						</div>
						<span class="additional">歡迎回來！</span>
					</div>
				</template>
				<ul>
					<div class="alwaysShow">
						<li>
							<router-link to="/">
								<div class="p-2 ps-3 py-2" @click="drawer = false">
									<i class="far fa-home"></i>
									<span>首頁</span>
								</div>
							</router-link>
						</li>
						<li @click="turnPage('ChargeMeContactBook')">
							<router-link to="/ChargeMeContactBook/ClassRoomCenter">
								<div class="p-2 ps-3 py-2" @click="drawer = false">
									<i class="far fa-book"></i>
									<span>喬米聯絡簿</span>
								</div>
							</router-link>
						</li>
						<!-- <li @click="turnPage('ChargeMeTribe')">
							<router-link to="/ChargeMeTribe">
								<div class="d-flex justify-content-between w-100">
									<div class="p-2 ps-3 py-2" @click="drawer = false">
										<i class="fa-sharp fa-regular fa-earth-americas"></i>
										<span>喬米部落</span>
									</div>
									<el-button
										link
										@click.stop="showPost = !showPost"
										@click.prevent
									>
										<i class="fas fa-caret-down"></i>
									</el-button>
								</div>
							</router-link>
						</li>
						<li v-if="showPost">
							<router-link :to="`/ChargeMeTribe/${currentUser().id}`">
								<div class="p-2 ps-5" @click="drawer = false">
									<i class="far fa-file-invoice"></i>
									<span>我的貼文</span>
								</div>
							</router-link>
						</li>
						<li v-if="showPost">
							<router-link :to="`/ChargeMeTribe/PostList`">
								<div class="p-2 ps-5" @click="drawer = false">
									<i class="far fa-file-invoice"></i>
									<span>貼文管理</span>
								</div>
							</router-link>
						</li> -->
					</div>
					<div
						v-if="currentUser().id != null && $route.path != '/'"
						class="afterLoginShow_SharedPage"
					>
						<li>
							<router-link to="/MemberCenter">
								<div class="p-2 ps-3" @click="drawer = false">
									<i class="far fa-user-alt"></i>
									<span>會員中心</span>
								</div>
							</router-link>
						</li>
						<li>
							<router-link to="/MessageBoard">
								<div class="p-2 ps-3" @click="drawer = false">
									<i class="fa-regular fa-comment-lines"></i>
									<span>留言回饋</span>
								</div>
							</router-link>
						</li>
					</div>
					<div
						v-if="
							currentUser().id != null &&
							($route.path.includes('ChargeMeContactBook') ||
								chargeMePage.includes('ChargeMeContactBook')) &&
							$route.path != '/'
						"
						class="afterLoginShow_ChargeMeContactBook"
					>
						<li>
							<router-link to="/ChargeMeContactBook/Payment">
								<div class="p-2 ps-3" @click="drawer = false">
									<i class="fa-regular fa-money-check-dollar"></i>
									<span>會員商店</span>
								</div>
							</router-link>
						</li>
						<li>
							<router-link to="/ChargeMeContactBook/Note">
								<div class="p-2 ps-3" @click="drawer = false">
									<i class="far fa-sticky-note"></i>
									<span>個人記事本</span>
								</div>
							</router-link>
						</li>
						<li>
							<router-link to="/ChargeMeContactBook/LineBot">
								<div class="p-2 ps-3" @click="drawer = false">
									<i class="fa-solid fa-user-robot"></i>
									<span>喬米秘書</span>
								</div>
							</router-link>
						</li>
					</div>
					<div
						v-if="
							currentUser().id != null &&
							($route.path.includes('ChargeMeTribe') ||
								chargeMePage.includes('ChargeMeTribe')) &&
							$route.path != '/'
						"
						class="afterLoginShow_ChargeMeTribe"
					>
						<li v-if="!currentUser().role.includes('Teacher')">
							<div class="p-2 ps-3 become_teacher" @click="becomeTeacher">
								<i class="far fa-chalkboard-teacher"></i>
								<span>成為老師</span>
							</div>
						</li>
						<li v-else>
							<router-link to="/ChargeMeContactBook/TeacherCenter">
								<div class="p-2 ps-3" @click="drawer = false">
									<i class="far fa-chalkboard-teacher"></i>
									<span>老師專區</span>
								</div>
							</router-link>
						</li>
						<li>
							<router-link to="/ChargeMeContactBook/StudentCenter">
								<div class="p-2 ps-3" @click="drawer = false">
									<i class="far fa-graduation-cap"></i>
									<span>學生專區</span>
								</div>
							</router-link>
						</li>
					</div>
					<div class="alwaysShow">
						<li>
							<router-link to="/About">
								<div class="p-2 ps-3" @click="drawer = false">
									<i class="far fa-info-circle"></i>
									<span>關於我們</span>
								</div>
							</router-link>
						</li>
						<div>
							<li v-if="!this.$store.state.auth.status.loggedIn">
								<router-link to="/login">
									<div class="p-2 ps-3" @click="drawer = false">
										<i class="far fa-sign-out-alt"></i>
										<span>登入/註冊</span>
									</div>
								</router-link>
							</li>
							<li v-else>
								<a @click.prevent="logout">
									<div class="p-2 ps-3" @click="drawer = false">
										<i class="far fa-sign-out-alt"></i>
										<span>登出</span>
									</div>
								</a>
							</li>
						</div>
					</div>
				</ul>
			</el-drawer>
		</div>
	</nav>
	<div
		class="bottomMenu_part"
		:class="{
			ChargeMeTribe_Page:
				($route.path.includes('ChargeMeTribe') ||
					chargeMePage.includes('ChargeMeTribe')) &&
				$route.path !== '/',
		}"
	>
		<div
			class="bottomMenu"
			v-if="
				$store.getters.mobileSize &&
				$route.path !== '/' &&
				$route.path !== '/EmailVerify' &&
				$route.path !== '/ChargeMeContactBook/Note' &&
				!$route.path.includes('/ChargeMeEnterpriseGPT') &&
				chargeMePage.includes('ChargeMeContactBook')
			"
		>
			<el-button
				class="ms-1 menu"
				v-if="this.$store.state.auth.status.loggedIn"
				@click="$store.state.isMessageShow = false"
			>
				<router-link to="/ChargeMeContactBook/ClassRoomCenter">
					<i
						class="fal fa-book"
						:class="{ activePage: classMenu && !$store.state.isMessageShow }"
					>
						<div class="activePageBlock">
							<div class="activePageBlock_rectangle"></div>
						</div>
						<div class="activePageBlock_left">
							<div class="activePageBlock_rectangle_left"></div>
						</div>
					</i>
					<br />
					<span
						:class="{
							activePage_Span: classMenu && !$store.state.isMessageShow,
						}"
						>課程選單</span
					>
				</router-link>
			</el-button>
			<el-button
				class="ms-1 menu"
				v-if="this.$store.state.auth.status.loggedIn"
				@click="$store.state.isMessageShow = false"
			>
				<router-link to="/ChargeMeContactBook/Curriculum">
					<i
						class="fal fa-calendar-week"
						:class="{
							activePage:
								$route.name == 'ChargeMeContactBook_Curriculum' &&
								!$store.state.isMessageShow,
						}"
					>
						<div class="activePageBlock">
							<div class="activePageBlock_rectangle"></div>
						</div>
						<div class="activePageBlock_left">
							<div class="activePageBlock_rectangle_left"></div></div
					></i>
					<br />
					<span
						:class="{
							activePage_Span:
								$route.name == 'ChargeMeContactBook_Curriculum' &&
								!$store.state.isMessageShow,
						}"
						>課&emsp;&emsp;表</span
					>
				</router-link>
			</el-button>
			<el-button
				class="ms-1 menu"
				v-if="this.$store.state.auth.status.loggedIn"
				@click="$store.state.isMessageShow = false"
			>
				<router-link to="/ChargeMeContactBook/HomeWork">
					<i
						class="fal fa-comment-alt-edit"
						:class="{
							activePage:
								$route.name == 'ChargeMeContactBook_HomeWork' &&
								!$store.state.isMessageShow,
						}"
					>
						<div class="activePageBlock">
							<div class="activePageBlock_rectangle"></div>
						</div>
						<div class="activePageBlock_left">
							<div class="activePageBlock_rectangle_left"></div></div
					></i>
					<br />
					<span
						:class="{
							activePage_Span:
								$route.name == 'ChargeMeContactBook_HomeWork' &&
								!$store.state.isMessageShow,
						}"
						>作業清單</span
					>
				</router-link>
			</el-button>
			<el-button
				@click="this.$store.commit('OpenMessageWin')"
				class="ms-1 message menu align-items-center"
				v-if="this.$store.state.auth.status.loggedIn"
			>
				<router-link to="">
					<i
						class="far fa-comments-alt"
						:class="{ activePage: $store.state.isMessageShow }"
					>
						<div class="activePageBlock">
							<div class="activePageBlock_rectangle"></div>
						</div>
						<div class="activePageBlock_left">
							<div class="activePageBlock_rectangle_left"></div>
						</div>
					</i>
					<br />
					<span :class="{ activePage_Span: $store.state.isMessageShow }"
						>即時通訊</span
					>
				</router-link>
			</el-button>
		</div>
		<div
			class="bottomMenu"
			v-if="
				$store.getters.mobileSize &&
				$route.path !== '/' &&
				$route.path !== '/EmailVerify' &&
				chargeMePage.includes('ChargeMeTribe')
			"
		>
			<el-button
				class="ms-1 menu"
				v-if="this.$store.state.auth.status.loggedIn"
				@click="$store.state.isMessageShow = false"
			>
				<router-link to="/ChargeMeTribe">
					<i
						class="fal fa-home"
						:class="{
							activePage:
								($route.name == 'ChargeMeTribe' ||
									$route.name == 'ChargeMeTribeIndex') &&
								!$store.state.isMessageShow,
						}"
					>
						<div class="activePageBlock">
							<div class="activePageBlock_rectangle"></div>
						</div>
						<div class="activePageBlock_left">
							<div class="activePageBlock_rectangle_left"></div>
						</div>
					</i>
					<br />
					<span
						:class="{
							activePage_Span:
								$route.name == 'ChargeMeTribe' && !$store.state.isMessageShow,
						}"
						>貼文</span
					>
				</router-link>
			</el-button>
			<el-button
				class="ms-1 menu"
				v-if="this.$store.state.auth.status.loggedIn"
				@click="$store.state.isMessageShow = false"
			>
				<router-link to="/ChargeMeTribe/ClassPage">
					<i
						class="fal fa-book"
						:class="{
							activePage:
								$route.name == 'ChargeMeTribe_ClassPage' &&
								!$store.state.isMessageShow,
						}"
					>
						<div class="activePageBlock">
							<div class="activePageBlock_rectangle"></div>
						</div>
						<div class="activePageBlock_left">
							<div class="activePageBlock_rectangle_left"></div></div
					></i>
					<br />
					<span
						:class="{
							activePage_Span: $route.name == '' && !$store.state.isMessageShow,
						}"
						>課程</span
					>
				</router-link>
			</el-button>
			<el-button
				class="ms-1 menu"
				v-if="this.$store.state.auth.status.loggedIn"
				@click="$store.state.isMessageShow = false"
			>
				<router-link to="/ChargeMeTribe/ChargeMeTribe_Connection">
					<i
						class="fal fa-people-dress-simple"
						:class="{
							activePage:
								$route.name == 'ChargeMeTribe_Connection' &&
								!$store.state.isMessageShow,
						}"
					>
						<div class="activePageBlock">
							<div class="activePageBlock_rectangle"></div>
						</div>
						<div class="activePageBlock_left">
							<div class="activePageBlock_rectangle_left"></div></div
					></i>
					<br />
					<span
						:class="{
							activePage_Span:
								$route.name == 'ChargeMeTribe_Connection' &&
								!$store.state.isMessageShow,
						}"
						>人脈</span
					>
				</router-link>
			</el-button>
			<el-button
				@click="this.$store.commit('OpenMessageWin')"
				class="ms-1 message menu align-items-center"
				v-if="this.$store.state.auth.status.loggedIn"
			>
				<router-link to="">
					<i
						class="far fa-comments-alt"
						:class="{ activePage: $store.state.isMessageShow }"
					>
						<div class="activePageBlock">
							<div class="activePageBlock_rectangle"></div>
						</div>
						<div class="activePageBlock_left">
							<div class="activePageBlock_rectangle_left"></div>
						</div>
					</i>
					<br />
					<span :class="{ activePage_Span: $store.state.isMessageShow }"
						>通訊</span
					>
				</router-link>
			</el-button>
		</div>
	</div>

	<MessageWindow v-show="this.$store.getters.isMessageShow" />
	<el-dialog
		v-model="editTagDialogVisible"
		title="編輯標籤"
		width="300px"
		center
		@close="resetTagDialog"
	>
		<div
			v-for="i in usersTags"
			:key="i"
			class="d-flex mb-3 editTagOption"
			:data-tagId="i.id"
			style="font-size: medium"
			@click="renameTag(i.id)"
		>
			<div class="d-flex align-items-center">
				<i
					v-if="renameId == i.id"
					class="trashTag far fa-trash-alt me-2"
					@click.stop.prevent="deleteConfirm(i.id)"
				></i>
				<i v-else class="fa-light fa-tag me-2"></i>
				<input
					class="renameInput"
					:id="'tagId_' + i.id"
					v-if="renameId == i.id"
					type="text"
					:value="i.name"
				/>
				<div v-else class="d-inline-block" style="width: 200px">
					{{ i.name }}
				</div>
				<el-button
					v-if="renameId == i.id"
					circle
					@click.stop.prevent="saveRename(i.id)"
				>
					<i style="width: 14px; height: 14px" class="fal fa-check"></i>
				</el-button>
				<el-button v-else circle>
					<i style="width: 14px; height: 14px" class="fal fa-pen"></i>
				</el-button>
			</div>
		</div>
	</el-dialog>
	<el-dialog
		v-model="editTagDialogVisible"
		title="編輯標籤"
		width="300px"
		center
		@close="resetTagDialog"
	>
		<div
			v-for="i in usersTags"
			:key="i"
			class="d-flex mb-3 editTagOption"
			:data-tagId="i.id"
			style="font-size: medium"
			@click="renameTag(i.id)"
		>
			<div class="d-flex align-items-center">
				<i
					v-if="renameId == i.id"
					class="trashTag far fa-trash-alt me-2"
					@click.stop.prevent="deleteConfirm(i.id)"
				></i>
				<i v-else class="fa-light fa-tag me-2"></i>
				<input
					class="renameInput"
					:id="'tagId_' + i.id"
					v-if="renameId == i.id"
					type="text"
					:value="i.name"
				/>
				<div v-else class="d-inline-block" style="width: 200px">
					{{ i.name }}
				</div>
				<el-button
					v-if="renameId == i.id"
					circle
					@click.stop.prevent="saveRename(i.id)"
				>
					<i style="width: 14px; height: 14px" class="fal fa-check"></i>
				</el-button>
				<el-button v-else circle>
					<i style="width: 14px; height: 14px" class="fal fa-pen"></i>
				</el-button>
			</div>
		</div>

		<template #footer>
			<span class="dialog-footer">
				<el-button
					type="primary"
					@click="
						editTagDialogVisible = false;
						saveRename(renameId);
					"
					>完成</el-button
				>
			</span>
		</template>
	</el-dialog>
</template>

<script>
import MessageWindow from "../components/ChargeMeContactBook/Message/MessageWindow.vue";
import Storage from "../models/storage";

export default {
	name: "Navbar",
	data() {
		return {
			searchStr: "",
			startSearch: false,
			renameId: "",
			drawer: false,
			classRoomDrawer: false,
			isTalk: false,
			targetGroupId: "",
			messageList: [],
			messageInfo: {},
			msgTargetInfo: {},
			breadClassRoomName: "",
			breadContactBookName: "",
			breadClassRoom: null,
			breadContactBook: null,
			canClassRoomShow: false,
			canContactBookShow: false,
			usersTags: [],
			chargeMePage: "",
			showPost: false,
			isLauncherOpen: false,
		};
	},
	props: ["activeCenterMode"],
	components: {
		MessageWindow,
	},
	created() {
		this.chargeMePage = "/ChargeMeContactBook/ClassRoomCenter";
		var setting = Storage.get("ChargeMe_Setting");
		if (setting != undefined && setting.page != undefined)
			this.chargeMePage = setting.page;
	},
	computed: {
		isLoginAndHasName() {
			let user = this.$store.state.auth.user;
			return user === null ? false : user.name === "" ? false : true;
		},
		classMenu() {
			let vm = this;
			let activeRouteNames = [
				"ChargeMeContactBook_ContactBook",
				"ChargeMeContactBook_ClassRoomCenter",
				"ChargeMeContactBook_ContactBook",
				"ChargeMeContactBook_ContactBookView",
				"ChargeMeContactBook_ContactBookViewById",
			];

			return activeRouteNames.includes(vm.$route.name);
		},
	},
	watch: {
		"$route.path"() {
			var setting = Storage.get("ChargeMe_Setting");
			if (setting != undefined && setting.page != undefined)
				this.chargeMePage = setting.page;
		},
		"$route.name"() {
			console.log(this.classMenu);
		},
		drawer: function (newVal, oldVal) {
			console.log(oldVal);
			if (newVal == false) this.showPost = false;
		},
	},
	methods: {
		toggleLauncher() {
			this.isLauncherOpen = !this.isLauncherOpen;
		},
		searchData() {
			let vm = this;
			this.$emit("childSearchTriggered", vm.searchStr);
		},
		logout() {
			let vm = this;
			if (vm.$route.path.includes("ChargeMeEnterpriseGPT")) {
				vm.$router.push("/EnterpriseLogin");
			} else {
				vm.$router.push("/login");
			}
			vm.$store.dispatch("auth/logout");
			vm.$hubConnection.stop();
		},
		addClassRoom() {
			this.$emit("addClassRoom");
		},
		turnPage(name) {
			var routePage;
			if (name == "ChargeMeContactBook") {
				routePage = "/ChargeMeContactBook/ClassRoomCenter";
				Storage.set("ChargeMe_Setting", {
					page: routePage,
				});
			} else if (name == "ChargeMeTribe") {
				routePage = "/ChargeMeTribe";
				Storage.set("ChargeMe_Setting", {
					page: routePage,
				});
			}

			this.$router.push(routePage);
		},
		handleOpen() {
			let vm = this;
			let apiPath = "";
			if (vm.$route.name == "ChargeMeContactBook_ContactBook") {
				apiPath = `${process.env.VUE_APP_URL_API}/ClassRoom/UserTag`;
			} else {
				apiPath = `${process.env.VUE_APP_URL_API}/ClassRoomCenter/UserTag`;
			}

			vm.fetchAPI("get", apiPath)
				.then((res) => {
					// console.log(res);
					vm.usersTags = res.data;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		handleSelect(tagId) {
			//編輯不篩選
			if (tagId == "editTag") return;
			this.$store.state.selectTagId = tagId;
		},
		renameTag(tagId) {
			let vm = this;
			vm.renameId = tagId;
			setTimeout(() => {
				document.getElementById(`tagId_${tagId}`).focus();
			}, 10);
		},
		saveRename(tagId) {
			let vm = this;
			if (document.getElementById(`tagId_${tagId}`) == null) return;
			let newValue = document.getElementById(`tagId_${tagId}`).value;
			let sameTagNames = vm.usersTags.filter(
				(x) => x.name == newValue && x.id != tagId
			);
			if (sameTagNames.length > 0) {
				vm.$message({
					type: "info",
					message: `已經有重複名稱`,
				});
				return false;
			}

			var o = {
				TagId: tagId,
				NewName: newValue,
			};

			let apiPath = "";
			if (vm.$route.name == "ChargeMeContactBook_ContactBook") {
				apiPath = `${process.env.VUE_APP_URL_API}/ClassRoom/RenameArticleTag/`;
			} else {
				apiPath = `${process.env.VUE_APP_URL_API}/ClassRoomCenter/RenameClassTag/`;
			}

			vm.fetchAPI("Post", apiPath, o)
				.then((res) => {
					if (res.status == 200) {
						vm.$message({
							type: "success",
							message: `編輯完成`,
						});
						vm.$emit("rerenderPage");
					}
				})
				.catch((err) => {
					console.log(err);
				});

			vm.usersTags.map((x) => {
				if (x.id == tagId) {
					x.name = newValue;
				}
			});
			vm.renameId = null;
			// vm.$emit("rerenderPage");
		},
		resetTagDialog() {
			let vm = this;
			vm.renameId = "";
		},
		deleteConfirm(tid) {
			let vm = this;

			let confirmMsg = `將刪除這個標籤，並將該標籤從您所有的課程中移除，但您的課程並不會被刪除。`;
			let apiPath = `${process.env.VUE_APP_URL_API}/ClassRoomCenter/RemoveClassTag`;
			if (vm.$route.name == "ChargeMeContactBook_ContactBook") {
				confirmMsg = `將刪除這個標籤，並將該標籤從您所有的筆記中移除，但您的筆記並不會被刪除。`;
				apiPath = `${process.env.VUE_APP_URL_API}/ClassRoom/RemoveArticleTag`;
			}

			vm.$confirm(confirmMsg, "", {
				confirmButtonText: "確認",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					var o = {
						TagId: tid,
						NewName: "",
					};
					vm.fetchAPI("post", apiPath, o)
						.then((res) => {
							if (res.status == 200) {
								vm.usersTags = vm.usersTags.filter((x) => x.id != tid);
								vm.$message({
									type: "success",
									message: `已刪除`,
								});
								vm.$emit("rerenderPage");
							} else {
								vm.$message({
									type: "warning",
									message: `刪除失敗`,
								});
							}
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch(() => {
					return;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.EnterpriseGPT {
	background-color: white !important;
}
.loginBtn {
	border: none;
	font-size: 16px;
	background-color: black;
	color: white;
	font-weight: bold;
	&:hover {
		background-color: white;
		color: black;
		span {
			a {
				text-decoration: none !important;
				color: black;
			}
		}
	}
	span {
		a {
			text-decoration: none !important;
			color: white;
		}
	}
}
.menu {
	position: relative;
	z-index: 20;
	font-size: 24px;
	background-color: transparent;
	border: none !important;
	border-radius: 25px;
	a {
		display: flex;
		align-items: center;
	}
	* {
		text-decoration: none;
	}
	i {
		color: #514832;
	}
	span {
		color: #514832 !important;
	}
}
.bottomMenu {
	position: fixed;
	left: 0;
	right: 0;
	margin: 0.25rem;
	bottom: 0;
	// z-index: 99;
	z-index: 98 !important;
	background-color: $main_color;
	padding: 0.25rem;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 8px;
	z-index: 10;
	display: flex;
	justify-content: space-around;
	// box-shadow: 3px 3px 3px rgba(0, 0, 0.14);
	.menu {
		background-color: transparent;
		border: none !important;
		border-radius: 999em !important;
		height: unset !important;
		padding: 0.25rem;
		a {
			display: block;
			span {
				color: 514832;
				font-size: xx-small !important;
				border-radius: 8px;
				padding: 0px 5px;
			}
		}
	}
	i {
		color: white !important;
		// padding: 0.25rem 0.5rem;
	}
	a {
		position: relative;
		.activePage {
			color: white !important;
			background-color: $main_color;
			width: 50px;
			height: 50px;
			line-height: 50px;
			border-radius: 99% !important;
			top: -52.5%;
			box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
			span {
				background-color: #fffdf8;
				color: 514832;
				font-size: xx-small !important;
				border-radius: 8px;
				padding: 0px 5px;
			}
			position: absolute;
			left: 0%;
			right: 0;
			margin: auto;
			&::after {
				content: "";
				display: block;
				width: 145%; /* 调整圆形的大小，这里设置为 150% */
				height: 145%; /* 调整圆形的大小，这里设置为 150% */
				background-color: white;
				// border: 2px solid white; /* 边框样式 */
				border-radius: 50%; /* 圆形 */
				position: absolute;
				top: 0; /* 调整位置以覆盖在.activePage之上 */
				bottom: 0;
				margin: auto;
				left: 50%; /* 将圆形居中 */
				transform: translateX(-50%); /* 使圆形水平居中 */
				z-index: -1; /* 将圆形放在 .activePage 后面 */
			}
			.activePageBlock {
				background-color: white;
				position: absolute;
				height: 20px;
				width: 20px;
				top: 0.25rem;
				bottom: 0;
				margin: auto;
				left: 60px;
				z-index: 2;
				.activePageBlock_rectangle {
					background-color: $main_color;
					position: absolute;
					height: 100%;
					width: 100%;
					border-radius: 20px 0 0 0;
					z-index: 9;
					right: 0;
				}
			}

			.activePageBlock_left {
				background-color: white;
				position: absolute;
				height: 20px;
				width: 20px;
				top: 0.25rem;
				right: 60px;
				z-index: 2;
				bottom: 0;
				margin: auto;

				.activePageBlock_rectangle_left {
					background-color: $main_color;
					position: absolute;
					height: 100%;
					width: 100%;
					border-radius: 0 20px 0 0;
					z-index: 9;
				}
			}
		}
		.activePage_Span {
			position: relative;
			z-index: 10;
			top: 8px;

			margin: auto;
			background-color: #fffdf8;
			box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);
		}
	}
}

#navbar {
	text-align: start;
	position: fixed;
	z-index: 10;
	top: 0;
	width: 100%;
	border-bottom: none !important;
	z-index: 10;
	.navbar-linear {
		background: linear-gradient(
			to top,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 1)
		) !important;
	}
	&.classroom_style {
		background-image: none;
		border-bottom: 1px solid #e0e0e0;
	}

	.navbar_top {
		display: flex;
		// box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.04);
		justify-content: space-between;
		align-items: center;

		background-color: #fffdf8;
		padding: 0 0.75rem;
		&.ChargeMeTribe_Page {
			background-color: #aac8f676;
		}
		.menu {
			font-size: 24px;
			background-color: transparent;
			border: none !important;
			border-radius: 25px;
			a {
				display: flex;
				align-items: center;
			}
			* {
				text-decoration: none;
			}
			i {
				color: #514832;
			}
			span {
				color: #514832 !important;
				font-size: xx-small;
				margin-left: 0.5rem;
			}
		}
		.activePage {
			background-color: white !important;
			box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15) !important;
		}
		h1 {
			font-size: 1.5rem;
			font-weight: $main_fontWeight;
			color: #fff;
		}
		.logo_search {
			display: flex;
			align-items: center;
			width: 50vw;
			a {
				display: flex;
				align-items: center;
				text-decoration: none;
				margin-right: 1rem;
				cursor: pointer;
				img {
					width: 1.5rem;
					margin-right: 0.5rem;
					&.fill {
						width: 2rem;
					}
				}
				&.ClassRoom {
					cursor: default;
					.ham,
					.logo {
						cursor: pointer;
					}
				}
			}
			.searchbar_pc {
				width: 25rem;
			}
		}
		.menu_user {
			display: flex;
			align-items: center;
			i {
				font-size: 1.3rem;
				// color: #ffffff;
				transition: $main_transition;
				&:hover {
					color: #000;
				}
			}
			.teacher_apply {
				color: $main_color;
				background-color: #fff;
				font-weight: 900;
				font-size: 1.1rem;
				border: 3px solid #fff;
				transition: $main_transition;
				&:hover {
					color: #fff;
					background-color: transparent;
				}
			}
		}
	}
}
.renameInput {
	border: none;
	width: 200px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.renameInput:focus {
	border: none;
	outline: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.activePage {
	// box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);
}

.trashTag {
	cursor: pointer;
}
.avatar {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	object-fit: cover;
	box-shadow: 1px 1px 3px 1px $main_gray;
}
.el-drawer {
	background: #f5f3f3;
	color: $text_color;
	i {
		width: 1.5rem;
		margin-right: 0.5rem;
		color: $text_color;
	}
	.top {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		margin-bottom: 1rem;
	}
	.img_avatar {
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		margin-right: 1rem;
		.avatar {
			width: 100%;
			object-fit: cover;
			box-shadow: 1px 1px 3px 1px $main_gray;
		}
	}
}
:deep .side_bar .el-drawer__header {
	margin-bottom: 0;
	padding: 1rem;
	padding-bottom: 0.6rem;
	box-shadow: 0px 0px 5px 0px rgb(121, 121, 121, 0.4);
	span {
		font-size: 1rem;
	}
	.main {
		font-weight: 700;
		width: 100%;
		&.computer {
			display: flex;
			align-items: flex-end;
			p {
				margin-left: 0.5rem;
			}
		}
		.name {
			font-size: 1.2rem;
			margin-bottom: 0.5rem;
		}
	}
}
:deep .el-drawer__body {
	padding: 0;
}
:deep .side_bar .el-drawer__body {
	padding: unset !important;
	ul {
		padding: 0;
		li {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		a,
		.become_teacher {
			display: inline-block;
			text-decoration: none;
			color: $text_color;
			width: 100%;
			transition: $main_transition;
			&:hover {
				background-color: $gray_hover;
			}
		}
	}
}
.ClassRoom {
	font-size: 1.2rem;
	display: flex;
	color: #5f6368;
	text-align: center;
	padding: 0.4rem 0rem 0.2rem 0;
	align-items: center;
	// justify-content: space-between;
	.logo {
		font-weight: 400;
	}
	a {
		// margin-left: 1rem;
		text-decoration: none;
		color: #5f6368;
	}
	i {
		cursor: pointer;
	}
}
.logoImg {
	width: 140px;
}
.addClass {
	cursor: pointer;
	// background-color: #9594949a;
	position: fixed;
	bottom: 10%;
	// border: 1px solid #9594949a;
	// border-radius: 50%;
	// padding: 1rem;
	right: 5%;
	i {
		font-size: 2rem !important;
	}
}
div.embossed-shadow {
	// margin: 30px 0px;
	// padding: 20px 0px;
	// background-color: #e9ecef;
	max-width: 900px;
}
div.embossed-shadow div {
	// margin: 30px auto;
	width: 60px;
	height: 60px;
	background-color: #fafcfd;

	border-radius: 99em;
}
div.embossed-shadow div:hover {
	transform: scale(1.03);
	transition: 0.1s;
}

/* 區塊陰影及高光效果 */
div.embossed-shadow div.black-shadow {
	box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
}
// div.embossed-shadow div.white-emboss {
//   box-shadow: -6px -6px 16px rgba(255, 255, 255, 1);
// }
/* 文字陰影 */
div.embossed-shadow div div {
	font-size: 48px;
	text-align: center;
	line-height: 44px;
	// text-shadow: 2px 2px rgba(255, 255, 255, 0.75);
}

:deep .el-drawer {
	.el-drawer__body {
		ul {
			direction: ltr !important;
		}
	}
	.el-drawer__header {
		direction: ltr !important;
	}
}

.bottomMenu_part {
	&.ChargeMeTribe_Page {
		.activePage,
		.bottomMenu,
		.activePageBlock_rectangle,
		.activePageBlock_rectangle_left {
			background-color: #aac8f6 !important;
		}
	}
}
.launcherBack {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}
.launcher {
	position: absolute;
	top: 50px;
	right: 20px;

	background: white;
	border: 1px solid #ccc;
	border-radius: 10px;

	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.optionItem:hover {
	cursor: pointer;
	text-decoration: underline;
}
@media screen and (min-width: 768px) {
	:deep .side_bar .el-drawer__header {
		.main {
			.name {
				margin-bottom: 0;
			}
		}
	}
}
.systemTitle {
	font-size: 12px;
	font-weight: 600;
}
</style>
